<script setup>
import {useFeatureFlags} from '~/store/featureFlags'

const props = defineProps({
  links: {
    type: Array,
    default: () => {},
  },
  hasToken: {
    type: Boolean,
    default: false
  }
});
const { t } = useI18n();
const router = useRouter();
const localePath = useLocalePath();
const switchLocalePath = useSwitchLocalePath();
const emits = defineEmits(["logout", "toggle-menu"]);
const showLanguage = ref(false);
const languages = ref([
  {
    name: t("ENGLISH"),
    code: "en",
  },
  {
    name: t("GERMAN"),
    code: "de",
  },
]);

const showSubscribePage = computed(() => {
  return useFeatureFlags().getFeatureFlags.website_subscription_page;
});

const showSwitchLanguage = computed(() => {
  return useFeatureFlags().getFeatureFlags.multi_currency;
});

const signin = () => {
  router.push(localePath({name: "signin"}))
  emits('toggle-menu')
}
</script>

<template>
  <div
    class="drop-down"
  >
    <div class="mobile-links">
      <nuxt-link
        v-for="(link, index) in links"
        :key="index"
        class="link-item block py-4 border-b border-solid border-info-400 text-base leading-4 text-secondary-500"
        :href="link.routeName"
        @click="emits('toggle-menu')"
      >
        {{ link.title }}
      </nuxt-link>
    </div>
    <div
      v-if="showSwitchLanguage"
      class="relative bg-white"
    >
      <div
        class="flex items-center justify-between mt-4"
        @click="showLanguage = !showLanguage"
      >
        <div class="flex items-center">
          <svg-language />
          <span
            class="ml-1 text-base leading-4 text-secondary-500"
            :class="{ 'text-secondary-400 font-semibold': showLanguage }"
            v-text="$t('LANGUAGE')"
          />
        </div>
        <svg-arrow-down :class="{ 'transform -rotate-180': showLanguage }" />
      </div>
      <div
        v-if="showLanguage"
        class="languages"
      >
        <nuxt-link
          v-for="(item, index) in languages"
          :key="index"
          :class="{ active: $i18n.locale === item.code }"
          :to="switchLocalePath(item.code)"
          @click="emits('toggle-menu')"
        >
          {{ item.name }}
        </nuxt-link>
      </div>
    </div>
    <div v-if="!showSubscribePage">
      <sc-button
        v-if="!hasToken"
        :primary="false"
        class="block w-full mt-24"
        :class="{ 'mt-36': showLanguage }"
        :label="$t('LOGIN')"
        @click="signin"
      />
      <sc-button
        v-else
        outlined
        class="block w-full mt-24"
        :class="{ 'mt-36': showLanguage }"
        :label="$t('LOGOUT')"
        @click="emits('logout')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/css/functions";

.drop-down {
  @apply bg-white overflow-y-auto fixed bottom-0 left-0 right-0 pt-4 pb-6 px-4 block xl:hidden;
  top: rem(65px);
  @media (min-width: 1024px) {
    top: rem(81px);
  }
  .mobile-links {
    .link-item {
      &.router-link-exact-active {
        @apply text-secondary-400 font-semibold;
      }
    }
  }
}
.languages {
  @apply absolute -left-4 -right-4 top-full mt-3;
  a {
    @apply block border-b border-solid text-base leading-4 text-secondary-500 p-4 font-normal;
    &.active {
      @apply font-semibold text-secondary-300 bg-primary-100;
    }
  }
}
</style>
